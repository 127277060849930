// logo
import './svg/simscale_logo.svg'

// favicon
import './ico/favicon.ico'

// svgs
import './svg/folder.svg'
import './svg/folder-shared.svg'
import './svg/star.svg'
import './svg/folder-simulation-card.svg'
import './svg/projects-simulation-card.svg'
import './svg/earth.svg'
import './svg/lock-solid.svg'

import './svg/share-button.svg'
import './svg/copy-button.svg'
import './svg/edit-button.svg'
import './svg/move-button.svg'
import './svg/open-button.svg'
import './svg/star-button.svg'
import './svg/trash-button.svg'

import './svg/geometry-icon.svg'
import './svg/mesh-icon.svg'
import './svg/simulation-icon.svg'
import './svg/simulation-run-icon.svg'

import './svg/high-five.svg'

// pngs
import './png/simulation-card-thumb-demo.png'

// scripts
import './scripts/intercom.js'
import './scripts/newrelic.js'
import './scripts/osano.js'
